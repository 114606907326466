@import url('https://fonts.googleapis.com/css2?family=Podkova:wght@400..800&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Rubik+Doodle+Shadow&family=Rubik+Spray+Paint&family=Source+Sans+3:ital,wght@0,200..900;1,200..900&family=Ubuntu+Condensed&display=swap');

:root {
    --color-bg:#282472;
    --color-txt:#101646;
    --color-txt-60:rgba(16,22,70,.6);
    --color-frm:#282472;
    --color-blu:#303891;
    --color-blu-60:rgba(48,56,145,.6);
    --color-blu-80:rgba(48,56,145,.8);
    --color-blu-90:rgba(48,56,145,.9);
    --color-lil:#7652e1;
    --color-lil-20:rgba(118,82,255,.2);
    --color-lil-30:rgba(118,82,255,.3);
    --color-lil-40:rgba(118,82,255,.4);
    --color-lil-60:rgba(118,82,255,.6);
    --color-lil-80:rgba(118,82,255,.8);
    --color-drk:rgba(31,33,79,1);
    --color-drk-30:rgba(31,33,79,.3);
    --color-drk-40:rgba(31,33,79,.)4;
    --color-drk-50:rgba(31,33,79,.5);
    --color-drk-60:rgba(31,33,79,.6);
    --color-drk-70:rgba(31,33,79,.7);
    --color-drk-80:rgba(31,33,79,.8);
    --color-drk-90:rgba(31,33,79,.9);
    --color-sun:rgba(248,128,33,1);
    --color-sun-30:rgba(248,128,33,.3);
    --color-sun-40:rgba(248,128,33,.4);
    --color-sun-60:rgba(248,128,33,.6);
    --color-sun-80:rgba(248,128,33,.8);
    --color-mar:rgba(87,164,227,1);
    --color-mar-30:rgba(87,164,227,.3);
    --color-mar-40:rgba(87,164,227,.4);
    --color-mar-60:rgba(87,164,227,.6);
    --color-mar-70:rgba(87,164,227,.7);
    --color-mar-80:rgba(87,164,227,.8);
    --color-mar-90:rgba(87,164,227,.9);
    --color-ult:rgba(15,45,206,1);
    --color-ult-30:rgba(15,45,206,.3);
    --color-ult-40:rgba(15,45,206,.4);
    --color-ult-50:rgba(15,45,206,.5);
    --color-ult-60:rgba(15,45,206,.6);
    --color-ult-70:rgba(15,45,206,.7);
    --color-ult-80:rgba(15,45,206,.8);
    --color-ult-90:rgba(15,45,206,.9);
    --color-yel:#ffb200;
    --color-yel-10:rgba(255,178,9,.1);
    --color-yel-20:rgba(255,178,9,.2);
    --color-yel-30:rgba(255,178,9,.3);
    --color-yel-40:rgba(255,178,9,.4);
    --color-yel-60:rgba(255,178,9,.6);
    --color-yel-80:rgba(255,178,9,.8);
    --color-yel-90:rgba(255,178,9,.9);
    --color-grn:#05ff51;
    --color-grn-30:rgba(5,255,81,.3);
    --color-grn-40:rgba(5,255,81,.4);
    --color-grn-50:rgba(5,255,81,.5);
    --color-grn-60:rgba(5,255,81,.6);
    --color-grn-70:rgba(5,255,81,.7);
    --color-grn-80:rgba(5,255,81,.8);
    --color-grn-90:rgba(5,255,81,.9);
    --color-brd:rgba(35,73,118,1);
    --color-brd-active:#5c9eec90;
    --color-brn:rgba(73,15,7,1);
    --color-brn-20:rgba(73,15,7,1);
    --color-brn-40:rgba(73,15,7,1);
    --color-brn-60:rgba(73,15,7,1);
    --color-brn-80:rgba(73,15,7,1);
    --color-brn-90:rgba(73,15,7,1);
    --color-wht:rgba(255,255,255,1);
    --color-wht-30:rgba(255,255,255,.3);
    --color-wht-50:rgba(255,255,255,.5);
    --color-wht-60:rgba(255,255,255,.6);
    --color-wht-70:rgba(255,255,255,.7);
    --color-wht-80:rgba(255,255,255,.8);
    --color-wht-90:rgba(255,255,255,.9);
    --color-blk:rgba(0,0,0,1);
    --color-blk-30:rgba(0,0,0,.3);
    --color-blk-60:rgba(0,0,0,.6);
    --color-blk-70:rgba(0,0,0,.7);
    --color-blk-80:rgba(0,0,0,.8);

    --font-capt:"Roboto", sans-serif;
    --font-logo:"Rubik Doodle Shadow", system-ui;
    --font-main:"Podkova", serif;
    --font-bold:"Ubuntu Condensed", sans-serif;
    --font-head:"Source Sans 3", sans-serif;

    --font-size-nm:1em;
    --font-size-sm:.9em;
    --font-size-xs:.85em;
    --font-size-xsm:.8em;
    --font-size-xxs:.7em;
    --font-size-md:1.1em;
    --font-size-lm:1.2em;
    --font-size-lg:1.3em;
    --font-size-lgs:1.6em;
    --font-size-lw:1.6em;
    --font-size-lx:2em;
    --font-size-lxm:2.16em;

    --border-r10:10px;
    --block-margin:.5vw .5vw;
    --form-submit:rgb(0, 97, 0);

    --transition: all .3s ease 0s;
    --transition-l: all .6s ease 0s;
    --text-shadow:0px 0px 3px var(--color-blk);
    --text-shadow-yel:0px 0px 9px var(--color-yel);
    --box-shadow:0px 0px 60px #ffffff20;   
    --box-shadow-yel:0px 0px 16px var(--color-yel-30);  
}

::placeholder {color: var(--color-wht-50);}
::-webkit-scrollbar {width:.6vw;background-color:var(--color-brd);}
::-webkit-scrollbar-thumb {background-color:var(--color-yel-60);}

* {margin:0;padding:0;}
body {background:var(--color-bg);color:var(--color-wht-70);font-family:var(--font-main);margin:130px 0 100px;overflow-x: hidden;}
h1 {width:90%;font-size:var(--font-size-lxm);font-family:var(--font-head);color:var(--color-blu);text-shadow:0px -1px 16px var(--color-yel-40);text-align:left;text-transform:uppercase;margin:3vh auto 6vh;}
h1.info {color:var(--color-wht-60);font-size:var(--font-size-lw);}
h2 {font-size:var(--font-size-lg);font-family:var(--font-capt);color:var(--color-yel-60);text-align:left;margin:2vh auto 3vh;}
h3 {color:var(--color-brn-80);}
p {line-height:130%;}
pre {font-size:var(--font-size-lg);text-align:center;margin:30px auto;}
a:link {color:var(--color-wht-80);text-decoration:none;}
a:visited {color:var(--color-wht-80);text-decoration:none;}
a:hover {color:var(--color-wht);}
label {cursor:pointer;display:block;width:100%;text-align:left;padding:1.6vh 0 1vh 0;font-size:var(--font-size-lm);color:var(--color-wht-60);}
input, textarea, select {font-size:var(--font-size-nm);width:100%;padding:1vh 1vw;margin-bottom:1vh;background-color:var(--color-blu-60);color:var(--color-wht-60);border:solid 1px var(--color-brd);border-radius:var(--border-r10);}
input:focus, select:focus, textarea:focus { outline: none !important;border-color:none;box-shadow: 0 0 10px var(--color-brd-active);background:var(--color-lil-60);}
input[type=checkbox] {height:2.5vh;margin-top:1.7vh;opacity:.6;}
input:-webkit-autofill {transition: all 5000s ease-in-out;}
textarea {font-size:var(--font-size-lg);width:94%;}
select option {background:var(--color-brd);color:var(--color-wht-60);text-shadow:none;border:none;}
select option:not(:checked) {background-color:var(--color-brd);}
input.sm {width:96%;}
button {font-size:var(--font-size-md);font-family:var(--font-capt);color:var(--color-wht);text-shadow:var(--text-shadow);font-weight:600;text-transform:uppercase;width:100%;max-width:330px;border:0;padding:1.6vh 3vw;margin:2vh 0 0;border-radius:var(--border-r10);cursor:pointer;background: linear-gradient(90deg, rgba(228,169,27,1) 0%, rgba(238,147,30,1) 50%, rgba(248,128,33,1) 100%);}
button:hover {background: linear-gradient(90deg, rgba(248,128,33,1) 0%, rgba(248,128,33,1) 50%, rgba(248,128,33,1) 100%);}
button[disabled] {background:var(--color-wht-60);opacity:.6;}
div.form-field-wrap {display:flex;justify-content:space-between;}
div.form-field-wrap label {width:40%;margin:0 0 .8vh 5%;}
div.form-field-wrap select {width:55%;}
.dtable {display:table;width:96%;margin:auto;}
.drow {display:table-row;}
.dcell {display:table-cell;}
.flex-side-left {width:57%;}
.flex-side-rght {width:37%;}
.relative {position:relative;}
.hidden {display:none;}
.white {color:var(--color-wht-80);}
.align-center {text-align:center;}
.loading {width:70px;height:60px;background:url("../loading.gif") center no-repeat;background-size:100% 100%;}
header {z-index:10;position:fixed;top:0;left:0;right:0;display:flex;justify-content:space-between;align-self:flex-start;background:var(--color-blu);box-shadow:0px 3px 60px var(--color-blk-30);}
header logo {display:flex;font-family:var(--font-logo);color:var(--color-wht);letter-spacing:-2px;font-size:var(--font-size-lgs);padding:1.8vh 2vw 1vh 3vw;}
header logo svg {height:92%;transform:scale(1.1);fill:var(--color-yel);}
header logo figcaption {margin:1.8vh 0 0 -5vw;z-index:3;font-size:var(--font-size-lgs);text-align:right;}
header logo figcaption b {color:var(--color-wht);background:var(--color-yel-40);padding:0 .6vw;text-shadow:0px 0px 6px var(--color-blk);border-radius:var(--border-r10);}
header logo figcaption span {display:block;margin:-.2vh .6vw 0 0;font-size:.5em;letter-spacing:.6px;font-weight:600;font-family:var(--font-head);color:var(--color-grn-70);text-transform:uppercase;}
header nav {z-index:3;position:absolute;bottom:0;right:2vw;display:flex;justify-content:space-around;height:5vh;font-family:var(--font-bold);letter-spacing:.16vw;text-transform:uppercase;min-width:700px;font-weight:800;}
header nav a:link,
header nav a:visited {background:var(--color-sun-60);color:var(--color-wht-70);font-size:var(--font-size-md);padding:1.5vh 2vw 1vh;margin:0 .3vw .1vh;width:24%;text-align:center;border-radius:var(--border-r10) var(--border-r10) 0 0;}
header nav a:hover {background:var(--color-sun);color:var(--color-wht);text-shadow:var(--text-shadow);}
header nav a.active {background:var(--color-sun);color:var(--color-wht);text-shadow:var(--text-shadow);}
header form {position:absolute;top:2vh;right:2vw;display:flex;justify-content:flex-end;min-width:600px;}
header form input {width:29%;background:var(--color-lil-60);padding:1vh 1vw;margin-bottom:0;}
header h4 {position:absolute;top:2vh;right:2vw;color:var(--color-sun);}
header button {width:30%;background:var(--color-grn-60);margin:0;padding:1vh 3vw;}
header button:hover {width:30%;background:var(--color-grn-80);}
header .react-reveal {z-index:5;position:absolute;top:0;left:-50%;}
header .error {padding:10px;font-size:var(--font-size-lm);border-radius:var(--border-r10);background:var(--color-yel-90);color:var(--color-brn);box-shadow:3px 6px 16px var(--color-drk);}
header .onliners {position:absolute;left:28%;width:180px;display:flex;justify-content:flex-end;align-items:flex-end;margin-top:2vh;color:var(--color-wht-50);}
header .onliners div {position:relative;width:20%;font-size:var(--font-size-lm);white-space:nowrap;}
header .onliners b {color:var(--color-grn-80);font-size:var(--font-size-lg);}
header .onliners svg {height:32px;fill:var(--color-grn-80);stroke:var(--color-yel);stroke-width:4;}
header .toggler {display:none;}

section.content {width:96%;min-width:1400px;margin:auto;display:flex;flex-wrap:wrap;justify-content:center;position:relative;}
  #register {width:57%;margin:var(--block-margin);background:var(--color-lil);border-radius:var(--border-r10);box-shadow:var(--box-shadow);overflow:hidden;}
  #register a:link,
  #register a:visited {display:block;color:var(--color-mar-90);font-size:var(--font-size-xxs);text-transform:none;}
  #register a:hover {color:var(--color-mar);}
  #register h1 svg {height:32px;margin-bottom:-10px;fill:var(--color-mar-90);}
  #register h4 {color:var(--color-sun);margin-bottom:20px;}
  #register form {width:40vw;min-width:300px;max-width:420px; margin-left:5%}
  #register form aside {display:flex;flex-wrap:wrap;justify-content:space-between;}
  #register form aside div:nth-child(1) {width:90%;}
  #register form aside div:nth-child(2) {width:50%;}
  #register form aside div:nth-child(3) {width:35%;}
  #register form label {width:100%;display:block;margin-bottom:2vh;color:var(--color-txt);font-size:var(--font-size-lgs);}
  #register form aside.checkbox {flex-wrap:nowrap;flex-shrink:0 1;}
  #register form aside.checkbox div:nth-child(1) {width:15%;} 
  #register form aside.checkbox div:nth-child(2) {width:70%;} 
  #register form aside.checkbox label {font-size:var(--font-size-lg);}
  #register form input, 
  #register form select {margin-bottom:3vh;padding:16px;font-size:var(--font-size-lg);}
  #register form select {margin-right:2vw;}
  #register form input[type=date] {font-size:var(--font-size-lgs);}
  #register form select:last-child {margin-right:-3vw;}
  #register form button {font-size:var(--font-size-lg);margin:3vh 0 5vh;width:100%;}
  #register form ::placeholder {color: var(--color-wht-90);}
  #register .error-param {position:absolute;top:-10vh;right:0;color:var(--color-drk);background:var(--color-yel);text-shadow:0;font-size:var(--font-size-md);font-weight:600;padding:20px 30px;margin-left:10px;border-radius:var(--border-r10);} 
  #register.redact {width:96% !important;margin:var(--block-margin);margin-bottom:20px;}
  #register.redact h1 {position:relative;}
  #register.redact h1 svg {position:absolute;right:0;top:0;height:50px;fill:var(--color-blu-80);cursor:pointer;}
  #register.redact h1 svg:hover {fill:var(--color-blu);transform:scale(-1, 1);}

  #user {width:96%;margin:var(--block-margin);background:var(--color-mar-60);border-radius:var(--border-r10);box-shadow:var(--box-shadow);overflow:hidden;}
  #user.stats {width:60%;}
  #user h1 {position:relative;}
  #user h1 svg {position:absolute;right:0;top:0;height:50px;fill:var(--color-blu-80);cursor:pointer;}
  #user h1 svg:hover {fill:var(--color-blu);transform:scale(-1, 1);}
  #user div.info {min-height:200px;padding-bottom:40px;}
  #user .profile figure {position:relative;white-space:nowrap;width:90%;margin:auto;}
  #user .profile figure div.info {width:65%;float:right;}
  #user .profile figure figcaption {display:flex;flex-wrap:wrap;justify-content:flex-start;font-size:var(--font-size-lx);font-family:var(--font-bold);color:var(--color-sun);font-weight:600;margin-top:1.6vh;}
  #user .profile figure figcaption div:nth-child(1) {margin:1.6vh 2vw 0 0;}
  #user .profile figure figcaption div:nth-child(2) {margin:0 2vw 0 auto;}
  #user .profile figure figcaption div:nth-child(3) {margin:-1.6vh 2vw 0 0;}
  #user .profile figure figcaption span {font-family:var(--font-main);color:var(--color-brd);font-size:.7em;}
  #user .profile figure figcaption span b {display:block;color:var(--color-grn-80);font-size:var(--font-size-sm);}
  #user .profile h2 {z-index:2;position:absolute;top:0;left:0;right:0;background:var(--color-frm);text-align:left;margin-bottom:0;padding:0 0 .6vh 2vw;}
  #user .profile p {padding:1.6vh 1vw 1vh;color:var(--color-wht-90);}
  #user .profile ul.svg-wrap {display:flex;flex-wrap:wrap;justify-content:space-between;margin:0;padding:0;white-space:normal;font-size:var(--font-size-lm);font-weight:600;}
  #user .profile ul.svg-wrap li {position:relative;width:5%;white-space:nowrap;font-size:var(--font-size-lw);padding:.5vh 0 .5vh 36px;margin:0 6px 20px 6px;color:var(--color-wht-50);list-style-type:none;display:inline-block;border-bottom:none;}  
  #user .profile ul.svg-wrap li:nth-child(1) {width:20%;font-size:var(--font-size-lm);}
  #user .profile ul.svg-wrap li:nth-child(2) {width:50%;font-size:var(--font-size-lm);}
  #user .profile ul.svg-wrap li:nth-child(6) {width:35%;}
  #user .profile ul.svg-wrap li svg{position:absolute;top:.5vh;left:0;}
  #user .profile svg.avatar {width:30%;max-width:180px;margin-right:.3vw;background:var(--color-lil);border-radius:50%;}
  #user .profile svg.ticket {width:30px;fill:var(--color-yel-80);transform:rotate(90deg) scale(1.1);}
  #user .profile svg.winner {width:30px;fill:var(--color-yel-80);}
  #user .profile svg.joined {width:30px;fill:var(--color-yel-80);}
  #user .profile svg.location {width:30px;fill:var(--color-yel-80);}
  #user .profile svg.email {width:30px;fill:var(--color-yel-80);}
  #user .profile svg.login {width:50px;fill:var(--color-grn-80);}
  #user .profile svg.chated {width:30px;fill:var(--color-yel-80);}
  #user .profile svg.balance {width:30px;stroke:var(--color-yel-80);stroke-width:5;fill:var(--color-yel-80);}

  #etherium {width:96%;margin:var(--block-margin);margin-top:20px;background:var(--color-grn-50);border-radius:var(--border-r10);box-shadow:var(--box-shadow);}
  #etherium h1 {color:var(--color-yel);text-shadow:var(--text-shadow);margin:0;}
  #etherium h3 {color:var(--color-ult);margin:.5em 0 .3em;}
  #etherium p {color:var(--color-yel);}
  #etherium button {margin:0;}
  #etherium section {width:90%;margin:auto;padding:30px 0;display:flex;flex-wrap:wrap;align-items:center;justify-content:space-between;}

  #logout {width:50%;min-width:500px;max-width:600px;display:flex;flex-wrap:nowrap;align-items:flex-start;justify-content:space-between;}
  #logout button {margin:2.5% 7% 0 0;background:var(--color-grn-60);}

  #user-info {display:flex;flex-wrap:nowrap;align-items:flex-start;justify-content:flex-start;margin-top:1vh;}
  #user-info ul.svg-wrap {width:100%;min-width:300px;max-width:300px;display:flex;flex-wrap:wrap;justify-content:flex-start;margin:0;padding:0;white-space:normal;font-size:var(--font-size-lm);font-weight:600;}
  #user-info ul.svg-wrap li {position:relative;width:auto;white-space:nowrap;width:8%;min-width:30px;font-size:var(--font-size-sm);padding:0 0 0 30px;margin:0;color:var(--color-wht-50);list-style-type:none;display:inline-block;border-bottom:none;}  
  #user-info ul.svg-wrap li:hover {color:var(--color-wht-90);}
  #user-info ul.svg-wrap li:nth-child(1) {width:100%;padding:0 0 .6vh 0;color:var(--color-yel-80);text-shadow:var(--text-shadow-yel);font-size:var(--font-size-nm);}
  #user-info ul.svg-wrap li svg {position:absolute;top:0;left:0;width:20px;fill:var(--color-wht-60);}
  #user-info svg.balance {width:18px !important;}
  #user-info svg.avatar {height:45px;margin-right:1vw;background:var(--color-yel-80);border-radius:50%;}

  #static {position:relative;width:57%;margin:var(--block-margin);background:var(--color-wht-60);border-radius:var(--border-r10);box-shadow:var(--box-shadow);overflow:hidden;}
  #static article {width:90%;margin:30px auto 50px;color:var(--color-blk-70);font-family:var(--font-bold);font-size:var(--font-size-lg);}
  #static article ul {margin:0px 20px 30px;}
  #static article ul li {padding:10px;}
  #static article h3 {margin:20px auto 10px;}
  #static article p {margin:0 auto 20px;}
  #static article b {font-weight:900;color:var(--color-blk);}
  #static a:link {color:var(--color-brn-80);text-decoration:none;background:var(--color-yel-60);padding:3px 10px;}
  #static a:visited {color:var(--color-brn-80);text-decoration:none;}
  #static a:hover {color:var(--color-brn);background:var(--color-yel);}

  #chart {position:relative;height:550px;width:60%;margin:var(--block-margin);background:var(--color-lil);border-radius:var(--border-r10);box-shadow:var(--box-shadow);overflow:hidden;}
  #chart canvas {position:absolute;left:0;right:0;bottom:0;margin-top:3vh;}
  #chart ul.years {z-index:2;position:absolute;top:0;left:0;right:0;text-align:center;font-size:var(--font-size-lg);}
  #chart ul.years li {display:inline-block;list-style-type:none;margin:2vh 2vw 0;cursor:pointer;color:var(--color-wht-60);}
  #chart ul.years li:hover {color:var(--color-wht);}
  #chart ul.years li.active {color:var(--color-wht);}
  #chart div.detail {position:absolute;left:4%;right:4%;top:10%;max-height:420px;overflow:auto;}
  #chart div.detail h2 {color:var(--color-yel-80);}
  #chart div.detail ul {display:flex;flex-wrap:wrap;justify-content:space-between;margin-bottom:80px;}
  #chart div.detail ul li {margin-bottom:10px;width:30%;min-width:250px;color:var(--color-blk);font-size:var(--font-size-md);text-shadow:none;list-style-type:none;cursor:pointer;}
  #chart div.detail ul li:hover {text-shadow:0px 0px 9px var(--color-yel);}
  #chart div.detail ul li span {font-size:var(--font-size-md);color:var(--color-wht-80);margin-left:9px;}
  #chart button.btn-detail {position:absolute;left:6%;right:6%;bottom:5%;width:88%;}

  #locations {width:80%;min-width:600px;max-width:1200px;margin:auto;overflow:hidden;}
  #locations canvas {min-height:600px;width:100%;}
  #locations div {margin-top:-50px;}

  #stats {height:550px;width:25%;margin:var(--block-margin);background:var(--color-blu);border-radius:var(--border-r10);box-shadow:var(--box-shadow);overflow:hidden;}
  #stats h2 {position:relative;text-align:left;margin-bottom:0;padding:0 0 .6vh 2vw;}
  #stats h4 {font-size:var(--font-size-lg);color:var(--color-mar);}
  #stats svg.bg {position:absolute;right:.6vw;top:-1.3vh;width:3vw;fill:var(--color-lil);}
  #stats:hover svg.bg {fill:var(--color-mar);}
  #stats .dtable {width:94%;margin:3vh auto 1vh;text-shadow:none;}
  #stats .drow {padding:0 1vw 1vh;text-transform:uppercase;color:var(--color-mar);font-size:var(--font-size-nm);border-bottom:dotted 2px var(--color-yel-60);cursor:pointer;}
  #stats .dcell {padding:.5vh 1vw;}
  #stats .dcell:nth-child(1) {text-align:left;color:var(--color-sun-80);}
  #stats .dcell:nth-child(2) {text-align:center;}
  #stats .dcell:nth-child(3) {text-align:right;font-weight:800;}
  #stats .dhead {border-bottom:dashed 2px var(--color-sun);}
  #stats .dhead .dcell:nth-child(1) {width:45%;text-align:left;}
  #stats .dhead .dcell:nth-child(2) {width:25%;text-align:center;}
  #stats .dhead .dcell:nth-child(3) {width:30%;text-align:right;}
  #stats .grn {color:var(--color-grn-60);font-size:var(--font-size-lg);}
  #stats .online {color:var(--color-grn-60) !important;font-weight:800;}
  #stats .grn svg {fill:var(--color-grn-60);height:11px;}
  #stats .drow:hover .dcell:nth-child(1) {color:var(--color-sun);text-shadow:0px 0px 9px var(--color-yel);}
  #stats .drow:hover .dcell:nth-child(2) {color:var(--color-wht);text-shadow:0px 0px 9px var(--color-yel);}
  #stats .drow:hover .grn {color:var(--color-grn);text-shadow:0px 0px 9px var(--color-yel);}
  #stats .dhead:hover .dcell:nth-child(1) {color:var(--color-mar);text-shadow:none;}
  #stats .dhead:hover .dcell:nth-child(2) {color:var(--color-mar);text-shadow:none;}
  #stats .dhead:hover .dcell:nth-child(3) {color:var(--color-mar);text-shadow:none;}
  #stats .active {background: linear-gradient(90deg, rgba(255,178,0,0) 11%, rgba(255,178,0,0.25253851540616246) 40%, rgba(255,178,0,0.29175420168067223) 60%, rgba(255,178,0,0) 100%);border-bottom:dotted 4px var(--color-yel);}
  #stats .active .grn {color:var(--color-grn);text-shadow:0px 0px 9px var(--color-drk);}
  #stats .active .dcell:nth-child(1) {color:var(--color-sun) !important;text-shadow:0px 0px 9px var(--color-drk);}
  #stats .active .dcell:nth-child(2) {color:var(--color-wht);text-shadow:0px 0px 9px var(--color-blk);}
  #stats .drow:last-child {border-bottom:none;}

  #news {position:relative;margin:var(--block-margin);padding:0;}
  #news aside {padding:2vh 1vw 2vh;background:var(--color-txt);border-radius:var(--border-r10);box-shadow:var(--box-shadow);}
  #news aside:hover {background:var(--color-txt-60);}
  #news aside article {margin-bottom:2vh;background:var(--color-txt);}
  #news p {display:none;border-top:solid 1px var(--color-brd);padding:2vh 1vw;margin-top:2vh;transition:var(--transition);}
  #news p.active {display:block;}
  #news h3 {color:var(--color-wht-20);position:relative;padding-left:3vw;cursor:pointer;}
  #news h3 svg {position:absolute;left:0;top:0;height:24px;fill:var(--color-yel-60);transform:rotate(0deg);}
  #news h3 span {color:var(--color-grn-80);text-shadow:var(--text-shadow);float:right;font-weight:300;}
  #news h3:hover svg {transform:rotate(45deg);fill:var(--color-yel);}
  #news h3 svg.active {transform:rotate(45deg);fill:var(--color-yel-80);}
  #news.news-home {width:37%;}
  #news.news-home aside {width:94.5%;margin:0 0 2vh auto;}
  #news.news-stat {width:96%;max-width:1330px;display:flex;flex-wrap:wrap;align-items:flex-start;justify-content:center;margin-top:2vh;}
  #news.news-stat aside {width:45%;margin:0 auto 2vh;}

  #chat {position:relative;margin:var(--block-margin);background: var(--color-frm);border-radius:var(--border-r10);box-shadow:var(--box-shadow);overflow:hidden;}
  #chat section {position:relative;}
  #chat figure {white-space:nowrap;}
  #chat figure div {width:70%;float:right;}
  #chat figure figcaption {display:flex;flex-wrap:wrap;justify-content:space-between;font-size:var(--font-size-lg);font-family:var(--font-bold);color:var(--color-mar);font-weight:600;margin-top:.3vh;}
  #chat figure figcaption span {position:relative;font-family:var(--font-main);color:var(--color-grn-80);font-size:var(--font-size-xsm);margin-top:.7vh;}
  #chat figure figcaption span svg {position:absolute;top:0;left:-20px;}
  #chat h2 {z-index:2;position:absolute;top:0;left:0;right:0;background:var(--color-frm);text-align:left;margin-bottom:0;padding:0 0 .6vh 2vw;}
  #chat svg.bg {position:absolute;right:.6vw;top:-1.3vh;width:2.5vw;fill:var(--color-lil);}
  #chat:hover svg.bg {fill:var(--color-mar);}  
  #chat p {padding:1.6vh 1vw 1vh;color:var(--color-wht-90);font-size:var(--font-size-md);text-shadow:0;}
  #chat ul.msg-wrap {display:flex;flex-wrap:wrap;width:96%;margin:7vh auto 2vh;overflow:auto;}
  #chat ul.msg-wrap li.msg {position:relative;list-style-type:none;padding:1vh 1vw;margin:1vh .6vw;border-bottom:solid 1px var(--color-brd);border-left:solid 1px var(--color-brd);border-radius:var(--border-r10);background:var(--color-lil-30);cursor:pointer;overflow:hidden;}
  #chat ul.msg-wrap li.msg:hover {background:var(--color-lil-60);box-shadow:var(--box-shadow-yel);}
  #chat ul.msg-wrap li.msg:hover ul.svg-wrap li {color:var(--color-wht);}
  #chat ul.msg-wrap li.msg:hover ul.svg-wrap li svg {fill:var(--color-yel);}
  #chat ul.svg-wrap {margin:0;padding:0;white-space:nowrap;font-size:var(--font-size-md);font-weight:600;}
  #chat ul.svg-wrap li {width:auto;position:relative;width:auto;min-width:24px;padding:.5vh 0 .5vh 30px;margin-bottom:0;text-align:left;color:var(--color-wht-50);list-style-type:none;display:inline-block;border-bottom:none;}  
  #chat ul.svg-wrap li:first-child {min-width:60px;}
  #chat ul.svg-wrap li svg{position:absolute;top:.5vh;left:0;fill:var(--color-yel-80);}
  #chat svg.avatar {width:60px;margin:3px .3vw 0 0;background:var(--color-lil);border-radius:50%;}
  #chat svg.anon {width:60px;margin-right:.3vw;background:var(--color-yel);border-radius:50%;}
  #chat svg.ticket {width:20px;transform:rotate(90deg) scale(1.1);}
  #chat svg.winner {width:20px;}
  #chat svg.joined {width:20px;}
  #chat svg.chated {width:20px;}
  #chat svg.remove {width:20px;fill:var(--color-sun);stroke:var(--color-sun);}
  #chat svg.login {width:16px;fill:var(--color-grn-80);}
  #chat svg.created {width:16px;fill:var(--color-grn-80);}
  #chat .online svg.avatar {background:var(--color-grn-60) !important;margin-top:3px;}
  #chat .online svg.anon {background:var(--color-grn-60) !important;margin-top:3px;}
  #chat .online h5 {position:absolute;top:70px;right:16px;color:var(--color-grn);font-style:italic;}
  #chat .online:hover svg.avatar {background:var(--color-grn-90) !important;}

  #chat.chat-home {width:49%;height:100%;max-height:420px;}
  #chat.chat-home ul.msg-wrap {max-height:350px;justify-content:space-between;}
  #chat.chat-home ul.msg-wrap li.msg {width:42.6%;min-width:240px;max-width:380px;}
  #chat.chat-stat {width:100%;max-height:700px;}
  #chat.chat-stat ul.msg-wrap {justify-content:center;max-height:600px;}
  #chat.chat-stat ul.msg-wrap li.msg {width:100%;}
  #chat.chat-stat ul.svg-wrap li {min-width:40px;}
  #chat.chat-stat ul.svg-wrap li:first-child {min-width:80px;}
  #chat.chat-user {width:86%;}
  #chat.chat-user ul.msg-wrap li.msg {width:29%;}
  #chat.chat-user ul.msg-wrap {justify-content:center;}
  #chat .error-param {z-index:3;position:absolute;top:3vh;left:2vw;color:var(--color-drk);background:var(--color-yel);text-shadow:0;font-size:var(--font-size-md);font-weight:600;padding:20px 30px;margin-left:10px;border-radius:var(--border-r10);} 

  #chat ul.opt-wrap {display:flex;flex-wrap:nowrap;justify-content:flex-end;width:96%;margin:1vh auto .6vh;}
  #chat ul.opt-wrap li {list-style-type:none;color:var(--color-sun-40);padding:0;margin:0;cursor:pointer;}
  #chat ul.opt-wrap li:hover {color:var(--color-sun);}
  #chat ul.deleted {justify-content:space-between !important;}

  #chat-form {position:relative;margin:var(--block-margin);}
  #chat-form form {width:100%;}
  #chat-form textarea {height:15vh;}
  #chat-form.chat-form-home {width:37%;}
  #chat-form.chat-form-stat {width:97%;margin-bottom:2vh;}
  #chat-form .react-reveal {position:absolute;top:10%;left:20%;width:50%;}
  #chat-form .error-param {color:var(--color-drk);background:var(--color-yel);text-shadow:0;font-size:var(--font-size-md);font-weight:600;padding:20px 30px;margin-left:10px;border-radius:var(--border-r10);} 
  .flex-side-rght #chat-form {margin-bottom:4vh;} 

  #newbe {position:relative;margin:var(--block-margin);background: var(--color-frm);border-radius:var(--border-r10);box-shadow:var(--box-shadow);overflow-x: hidden;}
  #newbe section {position:relative;display:flex;flex-wrap:wrap;}
  #newbe figure {white-space:nowrap;cursor:pointer;}
  #newbe figure div {width:70%;float:right;}
  #newbe figure figcaption {display:flex;flex-wrap:wrap;justify-content:space-between;font-size:var(--font-size-lg);font-family:var(--font-bold);color:var(--color-sun-80);font-weight:600;margin-top:.3vh;}
  #newbe figure figcaption span {position:relative;font-family:var(--font-main);color:var(--color-grn-80);font-size:var(--font-size-xsm);margin-top:.7vh;}
  #newbe h2 {z-index:2;position:absolute;top:0;left:0;right:0;background:var(--color-frm);text-align:left;margin-bottom:0;padding:0 0 .6vh 2vw;}
  #newbe svg.bg {position:absolute;right:.6vw;top:-1.3vh;width:2.5vw;fill:var(--color-lil);transition:var(--transition-l);}
  #newbe:hover svg.bg {fill:var(--color-mar);}
  #newbe ul.msg-wrap {display:flex;flex-wrap:wrap;margin:7vh auto 2vh;overflow:auto;}
  #newbe ul.msg-wrap li.msg {list-style-type:none;padding:1vh 1vw;margin:1vh .6vw;border-bottom:solid 1px var(--color-brd);border-left:solid 1px var(--color-brd);border-radius:var(--border-r10);background:var(--color-lil-30);overflow:hidden;}
  #newbe ul.msg-wrap li.msg:hover {background:var(--color-lil-60);box-shadow:var(--box-shadow-yel);}
  #newbe ul.msg-wrap li.msg:hover ul.svg-wrap li {color:var(--color-wht);}
  #newbe ul.msg-wrap li.msg:hover ul.svg-wrap li svg {fill:var(--color-yel);}
  #newbe ul.svg-wrap {margin:0;padding:0;white-space:nowrap;font-size:var(--font-size-md);font-weight:600;}
  #newbe ul.svg-wrap li {position:relative;width:90%;padding:.5vh 0 .5vh 1.2vw;margin-bottom:0;text-align:center;color:var(--color-wht-50);list-style-type:none;display:inline-block;border-bottom:none;}  
  #newbe ul.svg-wrap li svg{position:absolute;top:.5vh;left:0;fill:var(--color-yel-80);}
  #newbe svg.avatar {width:60px;margin-right:.3vw;background:var(--color-lil);border-radius:50%;}
  #newbe svg.joined {height:20px;}
  #newbe svg.login {height:13px;transform:scale(1.4);fill:var(--color-grn);}
  #newbe.newbe-home {width:49%;max-height:500px;}
  #newbe.newbe-home ul.msg-wrap {width:95%;justify-content:space-between;max-height:400px;}
  #newbe.newbe-home ul.msg-wrap li.msg {width:42.6%;min-width:255px;max-width:380px;}
  #newbe.newbe-stat {width:96%;max-width:1330px;box-sizing:border-box;margin-bottom:5vh;}
  #newbe.newbe-stat ul.msg-wrap {width:95%;justify-content:center;}
  #newbe.newbe-stat ul.msg-wrap li.msg {width:28%;min-width:240px;max-width:255px;}

  #tickets-list ul {margin:auto;padding:0;text-align:center;}
  #tickets-list ul li {text-align:left;position:relative;display:inline-block;list-style-type:none;text-shadow:0;cursor:pointer;}
  #tickets-list ul li span {display:block;color:var(--color-mar);}
  #tickets-list ul li span.id {color:var(--color-yel-80);}
  #tickets-list ul li svg.ticket {fill:var(--color-txt);}
  #tickets-list ul li:hover {z-index:2;transition:var(--transition);}
  #tickets-list ul li:hover aside {opacity:1;}
  #tickets-list ul li:hover svg.ticket {fill:var(--color-blk);}

  #tickets-list.main-list {width:100%;max-height:600px;padding:80px 0;margin:3vh auto;overflow:hidden;}
  #tickets-list.main-list ul {width:90%;}
  #tickets-list.main-list ul li {width:116px;height:40px;margin:-10px 0;padding:0;font-size:var(--font-size-xxs);}
  #tickets-list.main-list ul li span {font-size:var(--font-size-xsm);}
  #tickets-list.main-list ul li aside {position:absolute;top:20px;left:20px;opacity:.7;}
  #tickets-list.main-list ul li:hover {transform:scale(1.6);}
  
  #tickets-list.user-list {width:100%;margin:-5vh auto 4vh;padding:20px 0;}
  #tickets-list.user-list ul {width:100%;}
  #tickets-list.user-list ul li {width:200px;height:40px;margin:-10px 0;padding:0;font-size:var(--font-size-lm);}
  #tickets-list.user-list ul li span {font-size:var(--font-size-md);}
  #tickets-list.user-list ul li aside {position:absolute;top:20px;left:20px;opacity:.7;}
  #tickets-list.user-list ul li:hover {transform:scale(1.2);}


  #tickets-list.stat-list {width:100%;max-height:600px;padding:20px 0 0;margin:auto;overflow:hidden;}
  #tickets-list.stat-list ul {width:100%;}
  #tickets-list.stat-list ul li {width:165px;height:30px;margin:-20px 0;padding:0;font-size:var(--font-size-md);}
  #tickets-list.stat-list ul li span {font-size:var(--font-size-sm);}
  #tickets-list.stat-list ul li aside {position:absolute;top:20px;left:20px;opacity:.7;}
  #tickets-list.stat-list ul li:hover {transform:scale(1.2);}

  #tickets-fund {z-index:2;position:absolute;bottom:10px;left:30%;right:30%;width:40%;}
  #tickets-fund canvas {margin:0 auto;transform:scale(1.3);}

footer {z-index:10;background:var(--color-drk);font-size:var(--font-size-lm);color:var(--color-yel-40);padding:2vh 2vw;text-align:center;position:fixed;bottom:0;left:0;right:0;box-shadow:0px -3px 60px var(--color-blk-30);}
footer:hover {color:var(--color-yel-90);}


/*----------------------------- СРЕДНИЕ ЭКРАНЫ [ 1400, 1200 px ] -------------------------------------*/

@media (max-width:1400px){
  h1 {width:90%;font-size:var(--font-size-lxm)}
  h2 {font-size:var(--font-size-lg);margin:2vh auto 3vh;}
  div.form-field-wrap label {width:40%;margin:0 0 .8vh 5%;}
  div.form-field-wrap select {width:55%;}
  .dtable {width:96%;}
  .flex-side-left {width:57%;}
  .flex-side-rght {width:37%;}
  header logo {letter-spacing:-2px;font-size:var(--font-size-lgs);padding:1.8vh 2vw 1vh 3vw;}
  header logo svg {height:92%;transform:scale(1.1);}
  header logo figcaption {margin:1.8vh 0 0 -5vw;font-size:var(--font-size-lgs);}
  header logo figcaption b {padding:0 .6vw;}
  header logo figcaption span {margin:-.2vh .6vw 0 0;font-size:.5em;letter-spacing:.6px;font-weight:600;}
  header nav {bottom:0;right:2vw;height:5vh;letter-spacing:.16vw;min-width:700px;font-weight:800;}
  header nav a:link,
  header nav a:visited {font-size:var(--font-size-md);padding:1.5vh 2vw 1vh;margin:0 .3vw .1vh;width:24%;}
  header form {position:absolute;top:2vh;right:2vw;min-width:600px;}
  header form input {width:29%;}
  header h4 {position:absolute;top:2vh;right:2vw;}
  header button {width:30%;margin:0;padding:1vh 3vw;}
  header button:hover {width:30%;}
  header .react-reveal {top:0;left:-50%;}
  header .error {padding:10px;}
  
  section.content {width:100%;min-width:1200px;margin:auto;}
    #register {width:57%;}
    #register form {width:40vw;min-width:300px;max-width:420px; margin-left:5%}
    #register form aside div:nth-child(1) {width:90%;}
    #register form aside div:nth-child(2) {width:50%;}
    #register form aside div:nth-child(3) {width:35%;}
    #register form label {width:100%;margin-bottom:2vh;font-size:var(--font-size-lgs);}
    #register form aside.checkbox div:nth-child(1) {width:15%;} 
    #register form aside.checkbox div:nth-child(2) {width:70%;} 
    #register form input, 
    #register form select {margin-bottom:3vh;padding:16px;font-size:var(--font-size-lg);}
    #register form select {margin-right:2vw;}
    #register form input[type=date] {font-size:var(--font-size-lgs);}
    #register form select:last-child {margin-right:-3vw;}
    #register form button {font-size:var(--font-size-lg);margin:3vh 0 5vh;width:100%;}
    #register .error-param {position:absolute;top:-10vh;right:0;font-size:var(--font-size-md);font-weight:600;padding:20px 30px;margin-left:10px;} 
  
    #user {width:96%;}
    #user.stats {width:70%;}
    #user div.info {min-height:200px;padding-bottom:40px;}
    #user .profile figure {position:relative;white-space:nowrap;width:90%;margin:auto;}
    #user .profile figure div.info {width:68%;float:right;}
    #user .profile figure figcaption {font-size:var(--font-size-lx);font-weight:400;margin-top:1.6vh;}
    #user .profile figure figcaption div:nth-child(1) {margin:1.6vh 2vw 0 0;}
    #user .profile figure figcaption div:nth-child(2) {margin:0 2vw 0 auto;}
    #user .profile figure figcaption div:nth-child(3) {margin:-1.6vh 2vw 0 0;}
    #user .profile figure figcaption span {font-size:.7em;}
    #user .profile figure figcaption span b {font-size:var(--font-size-sm);}
    #user .profile h2 {top:0;left:0;right:0;margin-bottom:0;padding:0 0 .6vh 2vw;}
    #user .profile p {padding:1.6vh 1vw 1vh;}
    #user .profile ul.svg-wrap {margin:0;padding:0;font-size:var(--font-size-lm);font-weight:600;}
    #user .profile ul.svg-wrap li {position:relative;width:5%;font-size:var(--font-size-lm);padding:.5vh 0 .5vh 36px;margin:0 6px 20px 6px;}  
    #user .profile ul.svg-wrap li:nth-child(1) {width:20%;font-size:var(--font-size-lm);}
    #user .profile ul.svg-wrap li:nth-child(2) {width:55%;font-size:var(--font-size-lm);}
    #user .profile ul.svg-wrap li:nth-child(6) {width:35%;}
    #user .profile ul.svg-wrap li svg{position:absolute;top:.5vh;left:0;}
    #user .profile svg.avatar {width:27%;max-width:180px;margin-right:.3vw;}
    #user .profile svg.ticket {width:24px;}
    #user .profile svg.winner {width:24px;}
    #user .profile svg.joined {width:24px;}
    #user .profile svg.location {width:24px;}
    #user .profile svg.email {width:24px;}
    #user .profile svg.login {width:50px;}
    #user .profile svg.chated {width:24px;}
    #user .profile svg.balance {width:24px;}
  
    #logout {width:50%;min-width:500px;max-width:600px;}
    #logout button {margin:2.5% 7% 0 0;}
  
    #user-info {margin-top:1vh;}
    #user-info ul.svg-wrap {width:100%;min-width:200px;max-width:300px;margin:0;padding:0;font-size:var(--font-size-lm);font-weight:600;}
    #user-info ul.svg-wrap li {width:8%;min-width:30px;font-size:var(--font-size-sm);padding:0 0 0 30px;margin:0;}  
    #user-info ul.svg-wrap li:nth-child(1) {width:100%;padding:0 0 .6vh 0;font-size:var(--font-size-nm);}
    #user-info ul.svg-wrap li svg {position:absolute;top:0;left:0;width:20px;}
    #user-info svg.balance {width:18px !important;}
    #user-info svg.avatar {height:45px;margin-right:1vw;}
  
    #static {width:57%;}
    #static article {width:90%;margin:30px auto 50px;font-size:var(--font-size-lg);}
    #static article ul {margin:0px 20px 30px;}
    #static article ul li {padding:10px;}
    #static article h3 {margin:20px auto 10px;}
    #static article p {margin:0 auto 20px;}
    #static article b {font-weight:900;}
    #static a:link {padding:3px 10px;}
  
    #chart {height:470px;width:70%;}
    #chart canvas {margin-top:3vh;}
    #chart ul.years {font-size:var(--font-size-lg);}
    #chart ul.years li {margin:2vh 2vw 0;}
    #chart div.detail {position:absolute;left:4%;right:4%;top:10%;max-height:420px;}
    #chart div.detail ul {margin-bottom:80px;}
    #chart div.detail ul li {margin-bottom:10px;width:30%;min-width:250px;font-size:var(--font-size-md);}
    #chart div.detail ul li span {font-size:var(--font-size-md);margin-left:9px;}
    #chart button.btn-detail {position:absolute;left:6%;right:6%;bottom:5%;width:88%;}
  
    #locations {width:95%;min-width:600px;max-width:1200px;}
    #locations canvas {min-height:600px;width:100%;}
    #locations div {margin-top:-50px;}
  
    #stats {height:470px;width:25%;}
    #stats h2 {margin-bottom:0;padding:0 0 .6vh 2vw;}
    #stats h4 {font-size:var(--font-size-lm);}
    #stats svg.bg {position:absolute;right:.6vw;top:-1.3vh;width:3vw;}
    #stats .dtable {width:98%;margin:2vh auto;}
    #stats .drow {padding:0 .6vw .6vh;font-size:var(--font-size-sm);}
    #stats .dcell {padding:.5vh 1vw;}
    #stats .dcell:nth-child(3) {font-weight:800;}
    #stats .grn {font-size:var(--font-size-lm);}
    #stats .grn svg {height:11px;}
    
    #news {position:relative;padding:0;}
    #news aside {padding:2vh 1vw 2vh;}
    #news aside article {margin-bottom:2vh;}
    #news p {padding:2vh 1vw;margin-top:2vh;}
    #news h3 {position:relative;padding-left:3vw;}
    #news h3 svg {position:absolute;left:0;top:0;height:24px;}
    #news h3 span {float:right;font-weight:300;}
    #news.news-home {width:40%;}
    #news.news-home aside {width:94.5%;margin:0 0 2vh auto;}
    #news.news-stat {width:96%;display:flex;flex-wrap:wrap;align-items:flex-start;justify-content:center;margin-top:2vh;}
    #news.news-stat aside {width:46.7%;margin:0 auto 2vh;}
  
    #chat figure div {width:70%;float:right;}
    #chat figure figcaption {font-size:var(--font-size-lg);font-weight:600;margin-top:.3vh;}
    #chat figure figcaption span {font-size:var(--font-size-xsm);margin-top:.7vh;}
    #chat figure figcaption span svg {position:absolute;top:0;left:-20px;}
    #chat h2 {position:absolute;top:0;left:0;right:0;margin-bottom:0;padding:0 0 .6vh 2vw;}
    #chat svg.bg {position:absolute;right:.6vw;top:-1.3vh;width:2.5vw;}
    #chat p {padding:1.6vh 1vw 1vh;font-size:var(--font-size-md);}
    #chat ul.msg-wrap {display:flex;flex-wrap:wrap;width:96%;margin:7vh auto 2vh;}
    #chat ul.msg-wrap li.msg {padding:1vh 1vw;margin:1vh .6vw;}
    #chat ul.svg-wrap {margin:0;padding:0;font-size:var(--font-size-md);font-weight:600;}
    #chat ul.svg-wrap li {width:auto;min-width:24px;padding:.5vh 0 .5vh 30px;margin-bottom:0;}  
    #chat ul.svg-wrap li:first-child {min-width:60px;}
    #chat ul.svg-wrap li svg{position:absolute;top:.5vh;left:0;}
    #chat svg.avatar {width:60px;margin-right:.3vw;}
    #chat svg.anon {width:60px;margin-right:.3vw;}
    #chat svg.ticket {width:20px;}
    #chat svg.winner {width:20px;}
    #chat svg.joined {width:20px;}
    #chat svg.chated {width:20px;}
    #chat svg.remove {width:20px;}
    #chat svg.login {width:16px;}
    #chat svg.created {width:16px;}
    #chat.chat-home {width:55%;height:100%;min-height:420px;}
    #chat.chat-home ul.msg-wrap {max-height:350px;justify-content:center;}
    #chat.chat-home ul.msg-wrap li.msg {width:42.8%;min-width:240px;max-width:380px;}
    #chat.chat-stat {width:100%;max-height:700px;}
    #chat.chat-stat ul.msg-wrap li.msg {width:100%;}
    #chat.chat-stat ul.msg-wrap {justify-content:center;}
    #chat.chat-stat ul.svg-wrap li {min-width:40px;}
    #chat.chat-stat ul.svg-wrap li:first-child {min-width:80px;}
    #chat.chat-user {width:86%;}
    #chat.chat-user ul.msg-wrap li.msg {width:29%;}
    #chat.chat-user ul.msg-wrap {justify-content:center;}
    #chat .error-param {z-index:3;position:absolute;top:3vh;left:2vw;font-size:var(--font-size-md);font-weight:600;padding:20px 30px;margin-left:10px;} 
  
    #chat ul.opt-wrap {display:flex;flex-wrap:nowrap;justify-content:flex-end;width:96%;margin:1vh auto .6vh;}
    #chat ul.opt-wrap li {padding:0;margin:0;}
  
    #chat-form form {width:100%;}
    #chat-form textarea {height:15vh;}
    #chat-form.chat-form-home {width:39%;}
    #chat-form.chat-form-stat {width:97%;margin-bottom:2vh;}
    #chat-form .react-reveal {position:absolute;top:10%;left:20%;width:50%;}
    #chat-form .error-param {font-size:var(--font-size-md);font-weight:600;padding:20px 30px;margin-left:10px;} 
    .flex-side-rght #chat-form {margin-bottom:4vh;} 
  
    #newbe section {position:relative;display:flex;flex-wrap:wrap;}
    #newbe figure div {width:70%;float:right;}
    #newbe figure figcaption {display:flex;flex-wrap:wrap;justify-content:space-between;font-size:var(--font-size-lg);font-weight:600;margin-top:.3vh;}
    #newbe figure figcaption span {position:relative;font-size:var(--font-size-xsm);margin-top:.7vh;}
    #newbe h2 {position:absolute;top:0;left:0;right:0;margin-bottom:0;padding:0 0 .6vh 2vw;}
    #newbe svg.bg {position:absolute;right:.6vw;top:-1.3vh;width:2.5vw;}
    #newbe ul.msg-wrap {display:flex;flex-wrap:wrap;margin:7vh auto 2vh;}
    #newbe ul.msg-wrap li.msg {padding:1vh 1vw;margin:1vh .6vw;}
    #newbe ul.svg-wrap {margin:0;padding:0;white-space:nowrap;font-size:var(--font-size-md);font-weight:600;}
    #newbe ul.svg-wrap li {width:90%;padding:.5vh 0 .5vh 1.2vw;margin-bottom:0;}  
    #newbe ul.svg-wrap li svg{position:absolute;top:.5vh;left:0;}
    #newbe svg.avatar {width:60px;margin-right:.3vw;}
    #newbe svg.joined {height:20px;}
    #newbe svg.login {height:13px;transform:scale(1.4);}
    #newbe.newbe-home {width:55%;max-height:500px;}
    #newbe.newbe-home ul.msg-wrap {width:95%;justify-content:space-between;max-height:400px;}
    #newbe.newbe-home ul.msg-wrap li.msg {width:43.7%;min-width:255px;max-width:380px;}
    #newbe.newbe-stat {width:96%;}
    #newbe.newbe-stat ul.msg-wrap {width:99%;}
    #newbe.newbe-stat ul.msg-wrap li.msg {width:23.5%;min-width:225px;max-width:250px;}
  
    #tickets-list ul {margin:auto;padding:0;}
  
    #tickets-list.main-list {width:100%;max-height:600px;padding:80px 0;margin:3vh auto;}
    #tickets-list.main-list ul {width:98%;}
    #tickets-list.main-list ul li {width:116px;height:40px;margin:-10px 0;padding:0;font-size:var(--font-size-xxs);}
    #tickets-list.main-list ul li span {font-size:.8em;}
    #tickets-list.main-list ul li aside {position:absolute;top:20px;left:20px;}
    #tickets-list.main-list ul li:hover {transform:scale(1.6);}
    
    #tickets-list.user-list {width:100%;margin:2vh auto;padding:20px 0;}
    #tickets-list.user-list ul {width:98%;}
    #tickets-list.user-list ul li {width:180px;height:40px;margin:-10px 0;padding:0;font-size:var(--font-size-lm);}
    #tickets-list.user-list ul li span {font-size:var(--font-size-md);}
    #tickets-list.user-list ul li aside {position:absolute;top:20px;left:20px;}
    #tickets-list.user-list ul li:hover {transform:scale(1.2);}
  
    #tickets-fund {z-index:2;position:absolute;bottom:10px;left:30%;right:30%;width:40%;}
    #tickets-fund canvas {margin:0 auto;transform:scale(1.3);}
  
  footer {padding:2vh 2vw;}
  
}


@media (max-width:1200px){
  body {margin:110px 0 90px;}
  h1 {width:90%;font-size:var(--font-size-lx)}
  h2 {font-size:var(--font-size-lg);margin:2vh auto 3vh;}
  div.form-field-wrap label {width:40%;margin:0 0 .8vh 5%;}
  div.form-field-wrap select {width:55%;}
  .dtable {width:96%;}
  .flex-side-left {width:57%;}
  .flex-side-rght {width:37%;}
  header logo {letter-spacing:-2px;font-size:var(--font-size-lg);padding:1.8vh 2vw 1vh 3vw;}
  header logo svg {height:92%;transform:scale(1.1);}
  header logo figcaption {margin:1.8vh 0 0 -5vw;font-size:var(--font-size-lgs);}
  header logo figcaption b {padding:0 .6vw;}
  header logo figcaption span {margin:-.2vh .6vw 0 0;font-size:.5em;letter-spacing:.6px;font-weight:600;}
  header nav {bottom:0;right:2vw;height:4vh;letter-spacing:.16vw;min-width:600px;font-weight:800;}
  header nav a:link,
  header nav a:visited {font-size:var(--font-size-nm);padding:1vh 2vw 1vh;margin:0 .3vw .1vh;width:24%;}
  header form {position:absolute;top:2vh;right:2vw;min-width:600px;}
  header form input {width:29%;}
  header h4 {position:absolute;top:2vh;right:2vw;}
  header button {width:30%;margin:0;padding:1vh 3vw;}
  header button:hover {width:30%;}
  header .onliners {left:21%;}
  header .react-reveal {top:0;left:-50%;}
  header .error {padding:10px;}
  
  section.content {width:100%;min-width:900px;margin:auto;}
    #register {width:57%;}
    #register form {width:40vw;min-width:300px;max-width:420px; margin-left:5%}
    #register form aside div:nth-child(1) {width:90%;}
    #register form aside div:nth-child(2) {width:50%;}
    #register form aside div:nth-child(3) {width:35%;}
    #register form label {width:100%;margin-bottom:2vh;font-size:var(--font-size-lgs);}
    #register form aside.checkbox div:nth-child(1) {width:15%;} 
    #register form aside.checkbox div:nth-child(2) {width:70%;} 
    #register form input, 
    #register form select {margin-bottom:3vh;padding:16px;font-size:var(--font-size-lg);}
    #register form select {margin-right:2vw;}
    #register form input[type=date] {font-size:var(--font-size-lgs);}
    #register form select:last-child {margin-right:-3vw;}
    #register form button {font-size:var(--font-size-lg);margin:3vh 0 5vh;width:100%;}
    #register .error-param {position:absolute;top:-10vh;right:0;font-size:var(--font-size-md);font-weight:600;padding:20px 30px;margin-left:10px;} 
  
    #user {width:96%;overflow:auto;}
    #user.stats {width:67%;}
    #user div.info {min-height:200px;padding-bottom:30px;}
    #user .profile figure {overflow:auto;position:relative;white-space:nowrap;width:90%;margin:auto;}
    #user .profile figure div.info {width:60%;float:right;}
    #user .profile figure figcaption {font-size:var(--font-size-lx);font-weight:400;margin-top:1.6vh;}
    #user .profile figure figcaption div:nth-child(1) {width:90%;margin:1.6vh 2vw 1.6vh 0;}
    #user .profile figure figcaption div:nth-child(2) {margin:0 2vw 0 auto;}
    #user .profile figure figcaption div:nth-child(3) {width:70%;margin:-1.6vh 2vw 0 0;}
    #user .profile figure figcaption span {font-size:.7em;}
    #user .profile figure figcaption span b {font-size:var(--font-size-sm);}
    #user .profile h2 {top:0;left:0;right:0;margin-bottom:0;padding:0 0 .6vh 2vw;}
    #user .profile p {padding:1.6vh 1vw 1vh;}
    #user .profile ul.svg-wrap {justify-content:flex-start;margin:0;padding:0;font-size:var(--font-size-lm);font-weight:600;}
    #user .profile ul.svg-wrap li {position:relative;width:10%;font-size:var(--font-size-lm);padding:.5vh 0 .5vh 36px;margin:0 6px 5px 6px;}  
    #user .profile ul.svg-wrap li:nth-child(1) {width:80%;font-size:var(--font-size-lm);}
    #user .profile ul.svg-wrap li:nth-child(2) {width:80%;font-size:var(--font-size-lm);}
    #user .profile ul.svg-wrap li:nth-child(6) {width:80%;}
    #user .profile ul.svg-wrap li svg{position:absolute;top:.5vh;left:0;}
    #user .profile svg.avatar {width:35%;max-width:240px;margin-right:.3vw;}
    #user .profile svg.ticket {width:24px;}
    #user .profile svg.winner {width:24px;}
    #user .profile svg.joined {width:24px;}
    #user .profile svg.location {width:24px;}
    #user .profile svg.email {width:24px;}
    #user .profile svg.login {width:50px;}
    #user .profile svg.chated {width:24px;}
    #user .profile svg.balance {width:24px;}
  
    #logout {width:50%;min-width:500px;max-width:600px;}
    #logout button {margin:2.5% 7% 0 0;}
  
    #user-info {margin-top:1vh;}
    #user-info ul.svg-wrap {width:100%;min-width:200px;max-width:300px;margin:0;padding:0;font-size:var(--font-size-lm);font-weight:600;}
    #user-info ul.svg-wrap li {width:8%;min-width:30px;font-size:var(--font-size-sm);padding:0 0 0 30px;margin:0;}  
    #user-info ul.svg-wrap li:nth-child(1) {width:100%;padding:0 0 .6vh 0;font-size:var(--font-size-nm);}
    #user-info ul.svg-wrap li svg {position:absolute;top:0;left:0;width:20px;}
    #user-info svg.balance {width:18px !important;}
    #user-info svg.avatar {height:45px;margin-right:1vw;}
  
    #static {width:57%;}
    #static article {width:90%;margin:30px auto 50px;font-size:var(--font-size-lg);}
    #static article ul {margin:0px 20px 30px;}
    #static article ul li {padding:10px;}
    #static article h3 {margin:20px auto 10px;}
    #static article p {margin:0 auto 20px;}
    #static article b {font-weight:900;}
    #static a:link {padding:3px 10px;}
  
    #chart {height:420px;width:67%;}
    #chart canvas {margin-top:3vh;}
    #chart ul.years {font-size:var(--font-size-lg);}
    #chart ul.years li {margin:2vh 2vw 0;}
    #chart div.detail {position:absolute;left:4%;right:4%;top:10%;max-height:420px;}
    #chart div.detail ul {margin-bottom:80px;}
    #chart div.detail ul li {margin-bottom:10px;width:30%;min-width:250px;font-size:var(--font-size-md);}
    #chart div.detail ul li span {font-size:var(--font-size-md);margin-left:9px;}
    #chart button.btn-detail {position:absolute;left:6%;right:6%;bottom:5%;width:88%;}
  
    #locations {width:95%;min-width:600px;max-width:1200px;}
    #locations canvas {min-height:600px;width:100%;}
    #locations div {margin-top:-50px;}
  
    #stats {height:420px;width:28%;}
    #stats h2 {margin-bottom:0;padding:0 0 .6vh 2vw;}
    #stats h4 {font-size:var(--font-size-md);}
    #stats svg.bg {position:absolute;right:.6vw;top:-1.3vh;width:3vw;}
    #stats .dtable {width:98%;margin:0 auto;}
    #stats .drow {padding:0 .6vw .6vh;font-size:var(--font-size-xs);}
    #stats .dcell {padding:.5vh 1vw;}
    #stats .dcell:nth-child(3) {font-weight:800;}
    #stats .grn {font-size:var(--font-size-lm);}
    #stats .grn svg {height:11px;}
    
    #news {position:relative;padding:0;}
    #news aside {padding:2vh 1vw 2vh;}
    #news aside article {margin-bottom:2vh;}
    #news p {padding:2vh 1vw;margin-top:2vh;}
    #news h3 {position:relative;padding-left:3vw;}
    #news h3 svg {position:absolute;left:0;top:0;height:24px;}
    #news h3 span {float:right;font-weight:300;}
    #news.news-home {width:50%;}
    #news.news-home aside {width:94.5%;margin:0 0 2vh auto;}
    #news.news-stat {width:96%;display:flex;flex-wrap:wrap;align-items:flex-start;justify-content:center;margin-top:2vh;}
    #news.news-stat aside {width:46.7%;margin:0 auto 2vh;}
  
    #chat figure div {width:70%;float:right;}
    #chat figure figcaption {font-size:var(--font-size-lg);font-weight:600;margin-top:.3vh;}
    #chat figure figcaption span {font-size:var(--font-size-xsm);margin-top:.7vh;}
    #chat figure figcaption span svg {position:absolute;top:0;left:-20px;}
    #chat h2 {position:absolute;top:0;left:0;right:0;margin-bottom:0;padding:0 0 .6vh 2vw;}
    #chat svg.bg {position:absolute;right:.6vw;top:-1.3vh;width:2.5vw;}
    #chat p {padding:1.6vh 1vw 1vh;font-size:var(--font-size-md);}
    #chat ul.msg-wrap {display:flex;flex-wrap:wrap;width:96%;margin:7vh auto 2vh;}
    #chat ul.msg-wrap li.msg {padding:1vh 1vw;margin:1vh .6vw;}
    #chat ul.svg-wrap {margin:0;padding:0;font-size:var(--font-size-md);font-weight:600;}
    #chat ul.svg-wrap li {width:auto;min-width:24px;padding:.5vh 0 .5vh 30px;margin-bottom:0;}  
    #chat ul.svg-wrap li:first-child {min-width:60px;}
    #chat ul.svg-wrap li svg{position:absolute;top:.5vh;left:0;}
    #chat svg.avatar {width:60px;margin-right:.3vw;}
    #chat svg.anon {width:60px;margin-right:.3vw;}
    #chat svg.ticket {width:20px;}
    #chat svg.winner {width:20px;}
    #chat svg.joined {width:20px;}
    #chat svg.chated {width:20px;}
    #chat svg.remove {width:20px;}
    #chat svg.login {width:16px;}
    #chat svg.created {width:16px;}
    #chat.chat-home {width:45%;min-height:420px;}
    #chat.chat-home ul.msg-wrap {max-height:350px;justify-content:space-between;}
    #chat.chat-home ul.msg-wrap li.msg {width:90%;min-width:320px;max-width:450px;}
    #chat.chat-stat {width:100%;max-height:700px;}
    #chat.chat-stat ul.msg-wrap li.msg {width:100%;}
    #chat.chat-stat ul.msg-wrap {justify-content:center;}
    #chat.chat-stat ul.svg-wrap li {min-width:40px;}
    #chat.chat-stat ul.svg-wrap li:first-child {min-width:80px;}
    #chat.chat-user {width:86%;}
    #chat.chat-user ul.msg-wrap li.msg {width:29%;}
    #chat.chat-user ul.msg-wrap {justify-content:center;}
    #chat .error-param {z-index:3;position:absolute;top:3vh;left:2vw;font-size:var(--font-size-md);font-weight:600;padding:20px 30px;margin-left:10px;} 
  
    #chat ul.opt-wrap {display:flex;flex-wrap:nowrap;justify-content:flex-end;width:96%;margin:1vh auto .6vh;}
    #chat ul.opt-wrap li {padding:0;margin:0;}
  
    #chat-form form {width:100%;}
    #chat-form textarea {height:21vh;}
    #chat-form.chat-form-home {width:33%;}
    #chat-form.chat-form-stat {width:97%;margin-bottom:2vh;}
    #chat-form .react-reveal {position:absolute;top:10%;left:20%;width:50%;}
    #chat-form .error-param {font-size:var(--font-size-md);font-weight:600;padding:20px 30px;margin-left:10px;} 
    .flex-side-rght #chat-form {margin-bottom:4vh;} 
  
    #newbe section {position:relative;display:flex;flex-wrap:wrap;}
    #newbe figure div {width:70%;float:right;}
    #newbe figure figcaption {display:flex;flex-wrap:wrap;justify-content:space-between;font-size:var(--font-size-lg);font-weight:600;margin-top:.3vh;}
    #newbe figure figcaption span {position:relative;font-size:var(--font-size-xsm);margin-top:.7vh;}
    #newbe h2 {position:absolute;top:0;left:0;right:0;margin-bottom:0;padding:0 0 .6vh 2vw;}
    #newbe svg.bg {position:absolute;right:.6vw;top:-1.3vh;width:2.5vw;}
    #newbe ul.msg-wrap {display:flex;flex-wrap:wrap;margin:7vh auto 2vh;}
    #newbe ul.msg-wrap li.msg {padding:1vh 1vw;margin:1vh .6vw;}
    #newbe ul.svg-wrap {margin:0;padding:0;white-space:nowrap;font-size:var(--font-size-md);font-weight:600;}
    #newbe ul.svg-wrap li {width:90%;padding:.5vh 0 .5vh 1.2vw;margin-bottom:0;}  
    #newbe ul.svg-wrap li svg{position:absolute;top:.5vh;left:0;}
    #newbe svg.avatar {width:60px;margin-right:.3vw;}
    #newbe svg.joined {height:20px;}
    #newbe svg.login {height:13px;transform:scale(1.4);}
    #newbe.newbe-home {width:60%;max-height:500px;}
    #newbe.newbe-home ul.msg-wrap {width:95%;justify-content:space-between;max-height:400px;}
    #newbe.newbe-home ul.msg-wrap li.msg {width:43.7%;min-width:255px;max-width:380px;}
    #newbe.newbe-stat {width:96%;}
    #newbe.newbe-stat ul.msg-wrap {width:99%;}
    #newbe.newbe-stat ul.msg-wrap li.msg {width:32%;min-width:250px;max-width:290px;}
  
    #tickets-list ul {margin:auto;padding:0;}
  
    #tickets-list.main-list {width:100%;max-height:600px;padding:80px 0;margin:3vh auto;}
    #tickets-list.main-list ul {width:98%;}
    #tickets-list.main-list ul li {width:116px;height:40px;margin:-10px 0;padding:0;font-size:var(--font-size-xxs);}
    #tickets-list.main-list ul li span {font-size:.8em;}
    #tickets-list.main-list ul li aside {position:absolute;top:20px;left:20px;}
    #tickets-list.main-list ul li:hover {transform:scale(1.6);}
    
    #tickets-list.user-list {width:100%;margin:2vh auto;padding:20px 0;}
    #tickets-list.user-list ul {width:98%;}
    #tickets-list.user-list ul li {width:170px;height:40px;margin:-10px 0;padding:0;font-size:var(--font-size-lm);}
    #tickets-list.user-list ul li span {font-size:var(--font-size-md);}
    #tickets-list.user-list ul li aside {position:absolute;top:20px;left:20px;}
    #tickets-list.user-list ul li:hover {transform:scale(1.2);}
  
    #tickets-fund {z-index:2;position:absolute;bottom:10px;left:30%;right:30%;width:40%;}
    #tickets-fund canvas {margin:0 auto;transform:scale(1.3);}
  
  footer {padding:2vh 2vw;}
}


/*----------------------------- МОБИЛЬНЫЕ УСТРОЙСТВА [ 992, 787 px ] -------------------------------------*/


@media (max-width:992px){
  body {margin:100px 0 90px;}
  h1 {width:100%;text-align:center;font-size:var(--font-size-lgs);}
  h2 {font-size:var(--font-size-lg);margin:2vh auto 3vh;}
  div.form-field-wrap label {width:40%;margin:0 0 .8vh 5%;}
  div.form-field-wrap select {width:55%;}
  .dtable {width:100%;}
  .flex-side-left {width:100%;}
  .flex-side-rght {width:100%;}
  header logo {letter-spacing:-2px;font-size:var(--font-size-lm);padding:1.8vh 2vw 1vh 3vw;}
  header logo svg {height:92%;transform:scale(1.1);}
  header logo figcaption {margin:1.8vh 0 0 -16%;font-size:var(--font-size-lgs);}
  header logo figcaption b {padding:0 .6vw;}
  header logo figcaption span {margin:-.2vh .6vw 0 0;font-size:.5em;letter-spacing:.6px;font-weight:600;}
  header nav {z-index:10;position:absolute;bottom:auto;top:100%;right:-200vw;display:flex;flex-wrap:wrap;justify-content:center;align-content:flex-start;align-items:flex-start;height:90vh;padding-top:3vh;background:var(--color-drk);width:96vw;min-width:300px;border-radius:0 0 var(--border-r10) var(--border-r10);transition:.25s ease-in-out;}
  header nav.active {right:2vw;}
  header nav a:link,
  header nav a:visited {background:none;color:var(--color-wht-70);font-size:var(--font-size-lg);padding:1.5vh 0 1.5vh 13vw;margin:auto;width:100%;text-align:left;border-radius:0 0 0 0;}
  header nav a:hover {background:var(--color-wht-30);}
  header nav a.active {background:var(--color-wht-60);color:var(--color-wht);text-shadow:var(--text-shadow);}
  header form {position:relative;top:5vh;right:0;display:flex;flex-wrap:wrap;justify-content:center;min-width:170px;max-width:300px;}
  header form input {width:100%;font-size:var(--font-size-lg);background:var(--color-lil-60);padding:2vh 2vw;margin-bottom:2vh;}
  header form a:link,
  header form a:visited {color:var(--color-sun-90);text-transform:none;font-size:var(--font-size-md);}
  header form a:hover {color:var(--color-sun-90);background:none;text-shadow:none;}
  header form .error {text-transform:none;}
  header h4 {position:absolute;top:2vh;right:2vw;}
  header button {width:100%;margin:0;font-size:var(--font-size-lg);padding:2vh 3vw;}
  header button:hover {width:100%;}
  header .react-reveal {top:-60px;left:0;width:100%;}
  header .error {padding:10px;}
  header .onliners {position:relative;left:auto;width:100%;max-width:300px;display:flex;justify-content:flex-end;align-items:flex-end;margin-top:8vh;color:var(--color-wht-50);}
  header .toggler {display:block;width:32px;height:20px;position:absolute;right:5vw;top:1vw;margin:30px auto;transform:rotate(0deg);transition: .5s ease-in-out;cursor:pointer;}
  header .toggler span {display:block;position:absolute;height:4px;width:100%;background:var(--color-wht-70);border-radius:10px;opacity:1;left:0;transform:rotate(0deg);transition:.25s ease-in-out;}
  header .toggler span:nth-child(1) {top:0;}
  header .toggler span:nth-child(2) {top:10px;}
  header .toggler span:nth-child(3) {top:20px;}
  header .toggler-open span:nth-child(1) {top:10px;transform:rotate(135deg);}
  header .toggler-open span:nth-child(2) {opacity:0;left:-32px;}
  header .toggler-open span:nth-child(3) {top:10px;transform:rotate(-135deg);}

  section.content {width:96%;min-width:340px;margin:auto;}
    #register {width:96%;margin-bottom:20px;}
    #register form {width:96%;min-width:300px;max-width:360px;margin:auto;}
    #register form aside div:nth-child(1) {width:90%;}
    #register form aside div:nth-child(2) {width:50%;}
    #register form aside div:nth-child(3) {width:35%;}
    #register form label {width:100%;margin-bottom:2vh;font-size:var(--font-size-lgs);}
    #register form aside.checkbox div:nth-child(1) {width:15%;} 
    #register form aside.checkbox div:nth-child(2) {width:70%;} 
    #register form input {margin-bottom:3vh;padding:16px;max-width:300px;font-size:var(--font-size-lg);}
    #register form select {margin-bottom:3vh;padding:16px;max-width:320px;font-size:var(--font-size-lg);}
    #register form select {margin-right:2vw;}
    #register form input[type=date] {font-size:var(--font-size-lgs);}
    #register form select:last-child {margin-right:-3vw;}
    #register form button {font-size:var(--font-size-lg);margin:3vh auto 5vh;padding:2vh 2vw;width:100%;}
    #register .error-param {position:absolute;top:-10vh;right:0;font-size:var(--font-size-md);font-weight:600;padding:20px 30px;margin-left:10px;} 
    #register.redact {margin:0 auto 20px;}
    #register.redact h1 svg {right:16px;top:0;height:40px;fill:var(--color-blu-80);cursor:pointer;}

    #user {width:96%;margin:auto;overflow:auto;}
    #user h1 svg {position:absolute;right:16px;top:0;height:40px;fill:var(--color-blu-80);cursor:pointer;}
    #user div.info {min-height:200px;padding-bottom:30px;}
    #user .profile figure {overflow:auto;position:relative;white-space:nowrap;width:90%;margin:auto;text-align:center;}
    #user .profile figure div.info {width:90%;float:none;margin:20px auto;}
    #user .profile figure figcaption {font-size:var(--font-size-lx);font-weight:400;margin-top:1.6vh;}
    #user .profile figure figcaption div:nth-child(1) {width:90%;margin:1.6vh 2vw 1.6vh 0;}
    #user .profile figure figcaption div:nth-child(2) {margin:0 2vw 0 auto;}
    #user .profile figure figcaption div:nth-child(3) {width:70%;margin:-1.6vh 2vw 0 0;}
    #user .profile figure figcaption span {font-size:.7em;}
    #user .profile figure figcaption span b {font-size:var(--font-size-sm);}
    #user .profile h2 {top:0;left:0;right:0;margin-bottom:0;padding:0 0 .6vh 2vw;}
    #user .profile p {padding:1.6vh 1vw 1vh;}
    #user .profile ul.svg-wrap {justify-content:flex-start;padding:0;font-size:var(--font-size-lm);font-weight:600;}
    #user .profile ul.svg-wrap li {position:relative;width:10%;text-align:left;padding:.5vh 0 .5vh 36px;margin:0 9px 13px 6px;}  
    #user .profile ul.svg-wrap li:nth-child(1) {width:80%;}
    #user .profile ul.svg-wrap li:nth-child(2) {width:80%;}
    #user .profile ul.svg-wrap li:nth-child(6) {width:80%;}
    #user .profile ul.svg-wrap li svg{position:absolute;top:.5vh;left:0;}
    #user .profile svg.avatar {width:80%;max-width:200px;}
    #user .profile svg.ticket {width:24px;}
    #user .profile svg.winner {width:24px;}
    #user .profile svg.joined {width:24px;}
    #user .profile svg.location {width:24px;}
    #user .profile svg.email {width:24px;}
    #user .profile svg.login {width:50px;}
    #user .profile svg.chated {width:24px;}
    #user .profile svg.balance {width:24px;}
  
    #etherium {width:96%;margin:20px auto -10px;background:var(--color-grn-50);border-radius:var(--border-r10);box-shadow:var(--box-shadow);}

    #logout {width:100%;flex-wrap:wrap;align-items:flex-start;justify-content:space-between;min-width:180px;max-width:280px;margin:auto;}
    #logout button {margin:3vh auto 0;width:90%;}
  
    #user-info {margin-top:1vh;}
    #user-info ul.svg-wrap {width:100%;min-width:170px;max-width:270px;margin:0;padding:0;font-size:var(--font-size-lm);font-weight:600;}
    #user-info ul.svg-wrap li {width:auto;min-width:40px;font-size:var(--font-size-sm);padding:0 0 0 30px;margin:10px;}  
    #user-info ul.svg-wrap li:nth-child(1) {width:70%;padding:0 0 .6vh 0;font-size:var(--font-size-nm);text-transform:capitalize;}
    #user-info ul.svg-wrap li svg {position:absolute;top:0;left:0;width:20px;}
    #user-info svg.balance {width:18px !important;}
    #user-info svg.avatar {width:65px;margin:2vh 1vw 0 0;}
  
    #static {width:96%;margin:10px auto 20px;}
    #static article {width:92%;margin:20px auto 10px;font-size:var(--font-size-md);}
    #static article ul {margin:0px 20px 30px;font-size:var(--font-size-lm);}
    #static article ul li {padding:10px;}
    #static h1 {margin-bottom:0px;}
    #static article h3 {margin:20px auto 10px;}
    #static article p {margin:0 auto 20px;font-size:var(--font-size-lm);}
    #static article b {font-weight:900;}
    #static a:link {padding:3px 10px;}
  
    #chart {display:none;}
  
    #locations {display:none;}
  
    #stats {height:auto;width:96%;}
    #stats h2 {font-size:var(--font-size-lgs);margin-bottom:0;padding:0 0 .6vh 2vw;}
    #stats h4 {font-size:var(--font-size-lm);}
    #stats svg.bg {position:absolute;right:.6vw;top:-1.3vh;width:32px;}
    #stats .dtable {width:98%;margin:2vh auto;}
    #stats .drow {padding:0 .6vw .6vh;font-size:var(--font-size-md);}
    #stats .dcell {padding:.5vh 1vw;}
    #stats .dcell:nth-child(3) {font-weight:800;}
    #stats .grn {font-size:var(--font-size-ls);}
    #stats .grn svg {height:11px;}
    
    #news {position:relative;padding:0;}
    #news aside {padding:2vh 1vw 2vh;}
    #news aside article {margin-bottom:2vh;}
    #news p {padding:2vh 3vw;margin-top:2vh;font-size:var(--font-size-lm);}
    #news h3 {position:relative;padding:0 0 10px 30px;font-size:var(--font-size-md);}
    #news h3 svg {position:absolute;left:0;top:0;height:24px;}
    #news h3 span {float:right;font-weight:300;}
    #news.news-home {width:96%;}
    #news.news-home aside {width:96%;margin:2vh auto;}
    #news.news-stat {width:96%;display:flex;flex-wrap:wrap;align-items:flex-start;justify-content:center;margin-top:2vh;}
    #news.news-stat aside {width:96%;margin:0 auto 2vh;}
  
    #chat figure div {width:70%;float:right;}
    #chat figure figcaption {font-size:var(--font-size-lg);font-weight:600;margin-top:.3vh;}
    #chat figure figcaption span {font-size:var(--font-size-xsm);margin-top:.7vh;}
    #chat figure figcaption span svg {position:absolute;top:0;left:-20px;}
    #chat h2 {position:absolute;top:0;left:0;right:0;margin-bottom:0;padding:0 0 .6vh 2vw;}
    #chat svg.bg {position:absolute;right:.6vw;top:-1.3vh;width:32px;}
    #chat p {padding:1.6vh 1vw 1vh;font-size:var(--font-size-md);}
    #chat ul.msg-wrap {display:flex;flex-wrap:wrap;width:98%;margin:7vh auto 2vh;}
    #chat ul.msg-wrap li.msg {padding:1vh 1vw;margin:1vh .6vw;}
    #chat ul.svg-wrap {margin:0;padding:0;font-size:var(--font-size-md);font-weight:600;}
    #chat ul.svg-wrap li {width:auto;min-width:24px;padding:.5vh 0 .5vh 30px;margin-bottom:0;}  
    #chat ul.svg-wrap li:first-child {min-width:60px;}
    #chat ul.svg-wrap li svg{position:absolute;top:.5vh;left:0;}
    #chat svg.avatar {width:60px;margin-right:.3vw;}
    #chat svg.anon {width:60px;margin-right:.3vw;}
    #chat svg.ticket {width:20px;}
    #chat svg.winner {width:20px;}
    #chat svg.joined {width:20px;}
    #chat svg.chated {width:20px;}
    #chat svg.remove {width:20px;}
    #chat svg.login {width:16px;}
    #chat svg.created {width:16px;}
    #chat.chat-home {width:96%;min-height:200px;max-height:250px;height:250px;}
    #chat.chat-home ul.msg-wrap {max-height:200px;justify-content:space-between;}
    #chat.chat-home ul.msg-wrap li.msg {width:95%;min-width:280px;max-width:350px;}
    #chat.chat-stat {width:96%;min-height:200px;max-height:250px;height:250px;margin:0 auto 2vh;}
    #chat.chat-stat ul.msg-wrap {justify-content:center;max-height:180px;}
    #chat.chat-stat ul.msg-wrap li.msg {width:93%;min-width:280px;max-width:350px;}
    #chat.chat-stat ul.svg-wrap li {min-width:40px;}
    #chat.chat-stat ul.svg-wrap li:first-child {min-width:60px;}
    #chat.chat-user {width:86%;min-height:200px;max-height:250px;height:250px;}
    #chat.chat-user ul.msg-wrap {justify-content:center;max-height:200px;}
    #chat.chat-user ul.msg-wrap li.msg {width:93%;min-width:280px;max-width:350px;}
    #chat .error-param {z-index:3;position:absolute;top:3vh;left:2vw;font-size:var(--font-size-md);font-weight:600;padding:20px 30px;margin-left:10px;} 
  
    #chat ul.opt-wrap {display:flex;flex-wrap:nowrap;justify-content:flex-end;width:96%;margin:1vh auto .6vh;}
    #chat ul.opt-wrap li {padding:0;margin:0;}
  
    #chat-form form {width:92%;margin:2vh auto;}
    #chat-form textarea {height:160px;padding:10px;}
    #chat-form.chat-form-home {width:98%;}
    #chat-form.chat-form-stat {width:100%;margin-bottom:2vh;}
    #chat-form .react-reveal {position:absolute;top:10%;left:20%;width:50%;}
    #chat-form .error-param {font-size:var(--font-size-md);font-weight:600;padding:20px 30px;margin-left:10px;} 
    .flex-side-rght #chat-form {margin-bottom:4vh;} 
  
    #newbe section {position:relative;display:flex;flex-wrap:wrap;}
    #newbe figure div {width:70%;float:right;}
    #newbe figure figcaption {display:flex;flex-wrap:wrap;justify-content:space-between;font-size:var(--font-size-lg);font-weight:600;margin-top:.3vh;}
    #newbe figure figcaption span {position:relative;font-size:var(--font-size-xsm);margin-top:.7vh;}
    #newbe h2 {position:absolute;top:0;left:0;right:0;margin-bottom:0;padding:0 0 .6vh 2vw;}
    #newbe svg.bg {position:absolute;right:.6vw;top:-1.3vh;width:32px;}
    #newbe ul.msg-wrap {display:flex;flex-wrap:wrap;margin:7vh auto 2vh;overflow:hidden;}
    #newbe ul.msg-wrap li.msg {padding:1vh 1vw;margin:1vh .6vw;}
    #newbe ul.svg-wrap {margin:0;padding:0;white-space:nowrap;font-size:var(--font-size-md);font-weight:600;}
    #newbe ul.svg-wrap li {width:90%;padding:.5vh 0 .5vh 1.2vw;margin-bottom:0;}  
    #newbe ul.svg-wrap li svg{position:absolute;top:.5vh;left:0;}
    #newbe svg.avatar {width:60px;margin-right:.3vw;}
    #newbe svg.joined {height:20px;}
    #newbe svg.login {height:13px;transform:scale(1.4);}
    #newbe.newbe-home {width:96%;height:auto;}
    #newbe.newbe-home ul.msg-wrap {width:96%;justify-content:space-between;max-height:270px;overflow:hidden;}
    #newbe.newbe-home ul.msg-wrap li.msg {width:96%;min-width:280px;max-width:360px;}
    #newbe.newbe-stat {width:96%;}
    #newbe.newbe-stat ul.msg-wrap {width:99%;max-height:270px;}
    #newbe.newbe-stat ul.msg-wrap li.msg {width:100%;min-width:280px;max-width:340px;}
  
    #tickets-list ul {margin:auto;padding:0;}
  
    #tickets-list.main-list {width:100%;max-height:600px;padding:20px 0;margin:3vh auto;}
    #tickets-list.main-list ul {width:98%;}
    #tickets-list.main-list ul li {width:116px;height:40px;margin:-10px 0;padding:0;font-size:var(--font-size-xxs);}
    #tickets-list.main-list ul li span {font-size:.8em;}
    #tickets-list.main-list ul li aside {position:absolute;top:20px;left:20px;}
    #tickets-list.main-list ul li:hover {transform:scale(1.6);}
    
    #tickets-list.user-list {width:100%;margin:3vh auto;padding:20px 0;}
    #tickets-list.user-list ul {width:98%;}
    #tickets-list.user-list ul li {width:45%;max-width:180px;height:40px;margin:-10px 0;padding:0;font-size:var(--font-size-lm);}
    #tickets-list.user-list ul li span {font-size:var(--font-size-md);}
    #tickets-list.user-list ul li aside {position:absolute;top:20px;left:20px;}
    #tickets-list.user-list ul li:hover {transform:scale(1.2);}
  
    #tickets-fund {display:none;z-index:2;position:absolute;bottom:10px;left:30%;right:30%;width:40%;}
    #tickets-fund canvas {margin:0 auto;transform:scale(1.3);}
  
  footer {padding:1vh 0 1vh;font-size:var(--font-size-sm);}
}

@media (max-width:767px){
  /*
  h1 {width:90%;font-size:var(--font-size-lx)}
  h2 {font-size:var(--font-size-lg);margin:2vh auto 3vh;}
  div.form-field-wrap label {width:40%;margin:0 0 .8vh 5%;}
  div.form-field-wrap select {width:55%;}
  .dtable {width:100%;}
  .flex-side-left {width:100%;}
  .flex-side-rght {width:100%;}
  body {margin:150px 0 100px;}
  header {z-index:10;}
  header logo {z-index:1;letter-spacing:-2px;font-size:var(--font-size-lg);padding:1.8vh 2vw 1vh -3vw;}
  header logo svg {height:92%;transform:scale(1.1);}
  header logo figcaption {margin:1.8vh 0 0 -12vw;font-size:var(--font-size-lgs);}
  header h4 {position:absolute;top:2vh;right:2vw;}
  header .react-reveal {top:0;left:-50%;}
  header .error {padding:10px;}
  
  section.content {width:100%;min-width:900px;margin:auto;}
  #register {width:96%;}
  #register form {width:90%;min-width:240px;max-width:420px; margin:auto;}
  #register form aside div:nth-child(1) {width:90%;}
  #register form aside div:nth-child(2) {width:50%;}
  #register form aside div:nth-child(3) {width:35%;}
  #register form label {width:100%;margin-bottom:2vh;font-size:var(--font-size-lgs);}
  #register form aside.checkbox div:nth-child(1) {width:15%;} 
  #register form aside.checkbox div:nth-child(2) {width:70%;} 
  #register form input, 
  #register form select {margin-bottom:3vh;padding:16px;font-size:var(--font-size-lg);}
  #register form select {margin-right:2vw;}
  #register form input[type=date] {font-size:var(--font-size-lgs);}
  #register form select:last-child {margin-right:-3vw;}
  #register form button {font-size:var(--font-size-lg);margin:3vh 0 5vh;width:100%;}
  #register .error-param {position:absolute;top:-10vh;right:0;font-size:var(--font-size-md);font-weight:600;padding:20px 30px;margin-left:10px;} 

  #user {width:96%;overflow:auto;margin:auto;}
  #user div.info {min-height:200px;padding-bottom:30px;}
  #user .profile figure {overflow:auto;position:relative;white-space:nowrap;width:90%;margin:auto;}
  #user .profile figure div.info {width:60%;float:right;}
  #user .profile figure figcaption {font-size:var(--font-size-lx);font-weight:400;margin-top:1.6vh;}
  #user .profile figure figcaption div:nth-child(1) {width:90%;margin:1.6vh 2vw 1.6vh 0;}
  #user .profile figure figcaption div:nth-child(2) {margin:0 2vw 0 auto;}
  #user .profile figure figcaption div:nth-child(3) {width:70%;margin:-1.6vh 2vw 0 0;}
  #user .profile figure figcaption span {font-size:.7em;}
  #user .profile figure figcaption span b {font-size:var(--font-size-sm);}
  #user .profile h2 {top:0;left:0;right:0;margin-bottom:0;padding:0 0 .6vh 2vw;}
  #user .profile p {padding:1.6vh 1vw 1vh;}
  #user .profile ul.svg-wrap {justify-content:flex-start;margin:0;padding:0;font-size:var(--font-size-lm);font-weight:600;}
  #user .profile ul.svg-wrap li {position:relative;width:10%;font-size:var(--font-size-lm);padding:.5vh 0 .5vh 36px;margin:0 6px 5px 6px;}  
  #user .profile ul.svg-wrap li:nth-child(1) {width:80%;font-size:var(--font-size-lm);}
  #user .profile ul.svg-wrap li:nth-child(2) {width:80%;font-size:var(--font-size-lm);}
  #user .profile ul.svg-wrap li:nth-child(6) {width:80%;}
  #user .profile ul.svg-wrap li svg{position:absolute;top:.5vh;left:0;}
  #user .profile svg.avatar {width:35%;max-width:240px;margin-right:.3vw;}
  #user .profile svg.ticket {width:24px;}
  #user .profile svg.winner {width:24px;}
  #user .profile svg.joined {width:24px;}
  #user .profile svg.location {width:24px;}
  #user .profile svg.email {width:24px;}
  #user .profile svg.login {width:50px;}
  #user .profile svg.chated {width:24px;}
  #user .profile svg.balance {width:24px;}

  #static {width:96%;}
  #static article {width:90%;margin:30px auto 50px;font-size:var(--font-size-lg);}
  #static article ul {margin:0px 20px 30px;}
  #static article ul li {padding:10px;}
  #static article h3 {margin:20px auto 10px;}
  #static article p {margin:0 auto 20px;}
  #static article b {font-weight:900;}
  #static a:link {padding:3px 10px;}

  #chart {height:420px;width:96%;}
  #chart canvas {margin-top:3vh;}
  #chart ul.years {font-size:var(--font-size-lg);}
  #chart ul.years li {margin:2vh 2vw 0;}
  #chart div.detail {position:absolute;left:4%;right:4%;top:10%;}
  #chart div.detail ul {margin-bottom:80px;}
  #chart div.detail ul li {margin-bottom:10px;width:30%;min-width:250px;font-size:var(--font-size-md);}
  #chart div.detail ul li span {font-size:var(--font-size-md);margin-left:9px;}
  #chart button.btn-detail {position:absolute;left:6%;right:6%;bottom:5%;width:88%;}

  #locations {width:95%;min-width:600px;max-width:1200px;}
  #locations canvas {min-height:600px;width:100%;}
  #locations div {margin-top:-50px;}

  #stats {height:440px;width:96%;}
  #stats h2 {margin-bottom:0;padding:0 0 .6vh 2vw;}
  #stats h4 {font-size:var(--font-size-lm);}
  #stats svg.bg {position:absolute;right:.6vw;top:-1.3vh;width:3vw;}
  #stats .dtable {width:98%;margin:2vh auto;}
  #stats .drow {padding:0 .6vw .6vh;font-size:var(--font-size-sm);}
  #stats .dcell {padding:.5vh 1vw;}
  #stats .dcell:nth-child(3) {font-weight:800;}
  #stats .grn {font-size:var(--font-size-lm);}
  #stats .grn svg {height:11px;}
  
  #news {position:relative;padding:0;}
  #news aside {padding:2vh 1vw 2vh;}
  #news aside article {margin-bottom:2vh;}
  #news p {padding:2vh 1vw;margin-top:2vh;}
  #news h3 {position:relative;padding-left:3vw;}
  #news h3 svg {position:absolute;left:0;top:0;height:24px;}
  #news h3 span {float:right;font-weight:300;}
  #news.news-home {width:96%;}
  #news.news-home aside {width:100%;margin:2vh auto;}
  #news.news-stat {width:96%;display:flex;flex-wrap:wrap;align-items:flex-start;justify-content:center;margin-top:2vh;}
  #news.news-stat aside {width:96%;margin:2vh auto;}

  #chat figure div {width:70%;float:right;}
  #chat figure figcaption {font-size:var(--font-size-lg);font-weight:600;margin-top:.3vh;}
  #chat figure figcaption span {font-size:var(--font-size-xsm);margin-top:.7vh;}
  #chat figure figcaption span svg {position:absolute;top:0;left:-20px;}
  #chat h2 {position:absolute;top:0;left:0;right:0;margin-bottom:0;padding:0 0 .6vh 2vw;}
  #chat svg.bg {position:absolute;right:.6vw;top:-1.3vh;width:2.5vw;}
  #chat p {padding:1.6vh 1vw 1vh;font-size:var(--font-size-md);}
  #chat ul.msg-wrap {display:flex;flex-wrap:wrap;width:96%;margin:7vh auto 2vh;}
  #chat ul.msg-wrap li.msg {padding:1vh 1vw;margin:1vh .6vw;}
  #chat ul.svg-wrap {margin:0;padding:0;font-size:var(--font-size-md);font-weight:600;}
  #chat ul.svg-wrap li {width:auto;min-width:24px;padding:.5vh 0 .5vh 30px;margin-bottom:0;}  
  #chat ul.svg-wrap li:first-child {min-width:60px;}
  #chat ul.svg-wrap li svg{position:absolute;top:.5vh;left:0;}
  #chat svg.avatar {width:60px;margin-right:.3vw;}
  #chat svg.anon {width:60px;margin-right:.3vw;}
  #chat svg.ticket {width:20px;}
  #chat svg.winner {width:20px;}
  #chat svg.joined {width:20px;}
  #chat svg.chated {width:20px;}
  #chat svg.remove {width:20px;}
  #chat svg.login {width:16px;}
  #chat svg.created {width:16px;}
  #chat.chat-home {width:96%;height:460px;}
  #chat.chat-home ul.msg-wrap {max-height:390px;justify-content:space-between;}
  #chat.chat-home ul.msg-wrap li.msg {width:100%;min-width:350px;max-width:800px;}
  #chat.chat-stat {width:100%;max-height:700px;}
  #chat.chat-stat ul.msg-wrap li.msg {width:100%;}
  #chat.chat-stat ul.msg-wrap {justify-content:center;}
  #chat.chat-stat ul.svg-wrap li {min-width:40px;}
  #chat.chat-stat ul.svg-wrap li:first-child {min-width:80px;}
  #chat.chat-user {width:86%;}
  #chat.chat-user ul.msg-wrap li.msg {width:29%;}
  #chat.chat-user ul.msg-wrap {justify-content:center;}
  #chat .error-param {z-index:3;position:absolute;top:3vh;left:2vw;font-size:var(--font-size-md);font-weight:600;padding:20px 30px;margin-left:10px;} 

  #chat ul.opt-wrap {display:flex;flex-wrap:nowrap;justify-content:flex-end;width:96%;margin:1vh auto .6vh;}
  #chat ul.opt-wrap li {padding:0;margin:0;}

  #chat-form form {width:100%;}
  #chat-form textarea {height:290px;}
  #chat-form.chat-form-home {width:60%;}
  #chat-form.chat-form-stat {width:96%;margin:2vh auto;}
  #chat-form .react-reveal {position:absolute;top:10%;left:20%;width:50%;}
  #chat-form .error-param {font-size:var(--font-size-md);font-weight:600;padding:20px 30px;margin-left:10px;} 
  .flex-side-rght #chat-form {margin-bottom:4vh;} 

  #newbe section {position:relative;display:flex;flex-wrap:wrap;}
  #newbe figure div {width:70%;float:right;}
  #newbe figure figcaption {display:flex;flex-wrap:wrap;justify-content:space-between;font-size:var(--font-size-lg);font-weight:600;margin-top:.3vh;}
  #newbe figure figcaption span {position:relative;font-size:var(--font-size-xsm);margin-top:.7vh;}
  #newbe h2 {position:absolute;top:0;left:0;right:0;margin-bottom:0;padding:0 0 .6vh 2vw;}
  #newbe svg.bg {position:absolute;right:.6vw;top:-1.3vh;width:2.5vw;}
  #newbe ul.msg-wrap {display:flex;flex-wrap:wrap;margin:7vh auto 2vh;}
  #newbe ul.msg-wrap li.msg {padding:1vh 1vw;margin:1vh .6vw;}
  #newbe ul.svg-wrap {margin:0;padding:0;white-space:nowrap;font-size:var(--font-size-md);font-weight:600;}
  #newbe ul.svg-wrap li {width:90%;padding:.5vh 0 .5vh 1.2vw;margin-bottom:0;}  
  #newbe ul.svg-wrap li svg{position:absolute;top:.5vh;left:0;}
  #newbe svg.avatar {width:60px;margin-right:.3vw;}
  #newbe svg.joined {height:20px;}
  #newbe svg.login {height:13px;transform:scale(1.4);}
  #newbe.newbe-home {width:35%;height:420px;}
  #newbe.newbe-home ul.msg-wrap {width:96%;justify-content:space-between;max-height:400px;}
  #newbe.newbe-home ul.msg-wrap li.msg {width:96%;min-width:240px;max-width:300px;}
  #newbe.newbe-stat {width:96%;}
  #newbe.newbe-stat ul.msg-wrap {width:99%;}
  #newbe.newbe-stat ul.msg-wrap li.msg {width:47%;min-width:180px;max-width:400px;}

  #tickets-list ul {margin:auto;padding:0;}

  #tickets-list.main-list {width:100%;max-height:600px;padding:80px 0;margin:3vh auto;}
  #tickets-list.main-list ul {width:98%;}
  #tickets-list.main-list ul li {width:116px;height:40px;margin:-10px 0;padding:0;font-size:var(--font-size-xxs);}
  #tickets-list.main-list ul li span {font-size:.8em;}
  #tickets-list.main-list ul li aside {position:absolute;top:20px;left:20px;}
  #tickets-list.main-list ul li:hover {transform:scale(1.6);}
  
  #tickets-list.user-list {width:100%;margin:3vh auto;padding:20px 0;}
  #tickets-list.user-list ul {width:98%;}
  #tickets-list.user-list ul li {width:210px;height:40px;margin:-10px 0;padding:0;font-size:var(--font-size-lm);}
  #tickets-list.user-list ul li span {font-size:var(--font-size-md);}
  #tickets-list.user-list ul li aside {position:absolute;top:20px;left:20px;}
  #tickets-list.user-list ul li:hover {transform:scale(1.2);}

  #tickets-fund {z-index:2;position:absolute;bottom:10px;left:30%;right:30%;width:40%;}
  #tickets-fund canvas {margin:0 auto;transform:scale(1.3);}

footer {padding:2vh 2vw;}
*/
}